/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NumberInputDownProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const NumberInputDown: React.SFC<NumberInputDownProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <g id="Page-2" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="Desktop-1920-/-1080" stroke="currentColor">
        <g id="qty" transform="rotate(-180 6.5 5.5)">
          <path id="Rectangle-3-Copy" transform="rotate(135 5 5)" d="M8 8L2 8 2 2" />
        </g>
      </g>
    </g>
  </svg>
);
NumberInputDown.displayName = 'NumberInputDown';
/* tslint:enable */
/* eslint-enable */
