/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface TwitterProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Twitter: React.SFC<TwitterProps> = ({size, ...props}) => (
  <svg viewBox="0 0 16 16" fill="currentColor" width={ size || "16" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M15.238 3.962c-0.533 0.229-1.067 0.381-1.676 0.457 0.61-0.381 1.067-0.914 1.295-1.6-0.533 0.305-1.143 0.533-1.829 0.686-0.533-0.533-1.295-0.914-2.133-0.914-1.6 0-2.895 1.295-2.895 2.895 0 0.229 0 0.457 0.076 0.686-2.362-0.152-4.495-1.295-5.943-2.971-0.229 0.381-0.381 0.914-0.381 1.371 0 1.067 0.533 1.905 1.295 2.438-0.457 0-0.914-0.152-1.295-0.381 0 0 0 0 0 0 0 1.371 0.99 2.59 2.286 2.819-0.229 0.076-0.533 0.076-0.762 0.076-0.152 0-0.381 0-0.533-0.076 0.381 1.143 1.448 1.981 2.667 1.981-0.99 0.762-2.21 1.219-3.581 1.219-0.229 0-0.457 0-0.686-0.076 1.295 0.838 2.819 1.295 4.419 1.295 5.257 0 8.152-4.419 8.152-8.152 0-0.152 0-0.229 0-0.381 0.61-0.229 1.067-0.762 1.524-1.371z"
    />
  </svg>
);
Twitter.displayName = 'Twitter';
/* tslint:enable */
/* eslint-enable */
