/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NavArrowRightProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const NavArrowRight: React.SFC<NavArrowRightProps> = ({size, ...props}) => (
  <svg viewBox="0 0 17 16" fill="currentColor" width={ size || "1em" } height={ size || "1em" } {...props}>
    <path d="M6.077,1.162 C6.077,1.387 6.139,1.612 6.273,1.812 L10.429,8.041 L6.232,14.078 C5.873,14.619 6.019,15.348 6.56,15.707 C7.099,16.068 7.831,15.922 8.19,15.382 L12.82,8.694 C13.084,8.3 13.086,7.786 12.822,7.39 L8.233,0.51 C7.873,-0.032 7.141,-0.178 6.601,0.181 C6.26,0.409 6.077,0.782 6.077,1.162 L6.077,1.162 Z"
      fill="currentColor" fillRule="evenodd" />
  </svg>
);
NavArrowRight.displayName = 'NavArrowRight';
/* tslint:enable */
/* eslint-enable */
