/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NewRightArrowProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const NewRightArrow: React.SFC<NewRightArrowProps> = ({size, ...props}) => (
  <svg viewBox="0 0 15 24" fill="currentColor" width={ size || "15" } height={ size || "24" } {...props}>
    <g id="Right-Arrow" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="Icons-/-Arrow-/-Left-/-Default" fill="currentColor">
        <path id="Fill-1" transform="matrix(0 -1 -1 0 24 24)" d="M11.9994992 9L6 14.2409396 6.65704031 15 11.9994992 10.3338926 17.3429597 15 18 14.2409396z" />
      </g>
    </g>
  </svg>
);
NewRightArrow.displayName = 'NewRightArrow';
/* tslint:enable */
/* eslint-enable */
