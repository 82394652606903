/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface FacebookProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const Facebook: React.SFC<FacebookProps> = ({size, ...props}) => (
  <svg viewBox="0 0 8 16" fill="currentColor" width={ size || "8" } height={ size || "16" } {...props}>
    <path fill="currentColor" d="M7.829 3.505h-2.21c-0.229 0-0.533 0.305-0.533 0.762v1.6h2.743v2.21h-2.743v6.705h-2.514v-6.705h-2.362v-2.21h2.362v-1.295c0-1.905 1.295-3.429 3.124-3.429h2.133c0 0 0 2.362 0 2.362z"
    />
  </svg>
);
Facebook.displayName = 'Facebook';
/* tslint:enable */
/* eslint-enable */
