import {LOCAL_SERVER_PORT} from '../constants';

export const baseUrl = () => `http://localhost:${LOCAL_SERVER_PORT}/storefront/product/flying-turtle`;

export const baseUrlSSR = () => `http://localhost:${LOCAL_SERVER_PORT}/ssr/storefront/product/flying-turtle`;

export const defaultUrlParams = {
  layout: 'classic',
  deviceType: 'desktop',
  withOptions: 'true',
  locale: 'en',
  instance:
    'v0L76_a8qacHo83SpCczSiil1YfTx-gYVZG_VxF2wks.eyJpbnN0YW5jZUlkIjoiNGMwZGE0YzMtYWIyMy00OTQ5LTlmNTMtNzRkNmQ1MzJjNzMwIiwiYXBwRGVmSWQiOiIxMzgwYjcwMy1jZTgxLWZmMDUtZjExNS0zOTU3MWQ5NGRmY2QiLCJzaWduRGF0ZSI6IjIwMTctMTEtMTZUMTU6MzA6NTAuNzg1WiIsInVpZCI6bnVsbCwiaXBBbmRQb3J0IjoiMjEyLjI5LjI0Ni4yMjQvMzc4NDYiLCJ2ZW5kb3JQcm9kdWN0SWQiOm51bGwsImRlbW9Nb2RlIjpmYWxzZSwib3JpZ2luSW5zdGFuY2VJZCI6ImRlNDA4ZmMzLTJjYTUtNDZlMC1iZDUyLWRlOTU3Y2FjNzZiMiIsImFpZCI6ImNlNTdjYWNjLTFkMzQtNDBlYi1iN2Y4LThlMTg1NzRkYTA5NyIsImJpVG9rZW4iOiI1MzE3ZDM1Yy1lNDlkLTA1ZmEtMDQ2ZC03NjMxZDZiZjY2MzUiLCJzaXRlT3duZXJJZCI6IjZiNWJiNzg4LTdhOTAtNGE0YS05OTVkLWM2MTA2MmFkZjFhMiJ9',
};

export const slickAnimationSpeed = 400;
