/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface NoProductProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
export const NoProduct: React.SFC<NoProductProps> = ({size, ...props}) => (
  <svg viewBox="0 0 32 32" fill="currentColor" width={ size || "97" } height={ size || "97" } {...props}>
    <path fill="currentColor" d="M30.026 3.502h-8.903a2.3 2.3 0 0 0 .186-1.732c-.168-.633-.652-1.173-1.267-1.471S18.775.001 18.179.262c-.801.354-1.453 1.304-2.254 2.515-.801-1.211-1.434-2.161-2.254-2.515a2.245 2.245 0 0 0-1.863.037 2.416 2.416 0 0 0-1.267 1.471 2.3 2.3 0 0 0 .186 1.732H1.972A1.71 1.71 0 0 0 .258 5.216v25.071c0 .95.764 1.714 1.714 1.714h28.051a1.71 1.71 0 0 0 1.714-1.714V5.216a1.71 1.71 0 0 0-1.714-1.714zM18.645 1.304c.391-.168.727-.056.913.019.317.149.559.428.652.745.056.223.186.969-.689 1.434h-2.682c.652-1.006 1.285-1.975 1.807-2.198zm-6.985.763c.075-.298.335-.577.652-.745.168-.075.521-.205.913-.019.521.223 1.155 1.173 1.807 2.198H12.35c-.875-.447-.745-1.211-.689-1.434zM1.378 30.287V5.216c0-.335.261-.596.596-.596h13.374v26.263H1.974a.589.589 0 0 1-.596-.596zm29.244 0a.589.589 0 0 1-.596.596h-13.56V4.62h13.56c.335 0 .596.261.596.596v25.071z"
    />
  </svg>
);
NoProduct.displayName = 'NoProduct';
/* tslint:enable */
/* eslint-enable */
