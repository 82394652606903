export function dynamicLoadResource({type, linkAttr, ext, onload}): void {
  const _tag = document.createElement(type);
  const url = `https://static.parastorage.com/unpkg/@wix/magiczoomplus@5.2.6/magiczoomplus/magiczoomplus.`;
  if (ext === 'css') {
    _tag[linkAttr] = `${url}${ext}`;
    _tag.setAttribute('rel', 'stylesheet');
    _tag.setAttribute('type', 'text/css');
    _tag.setAttribute('media', 'screen');
    _tag.onload = onload;
  } else {
    _tag[linkAttr] = `${url}${ext}`;
  }
  _tag.onload = onload;
  document.body.appendChild(_tag);
}

export function getMagicZoomNodeId(index: number): string {
  return `magic-zoom-id-${index}`;
}
